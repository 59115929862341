import pick from 'lodash/pick';
import {TsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';

function getRenderer() {
    try {
        const canvas = document.createElement('canvas');

        if (canvas) {
            const context = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

            if (context) {
                const info = context.getExtension('WEBGL_debug_renderer_info');

                if (info) {
                    return context.getParameter(info.UNMASKED_RENDERER_WEBGL);
                }
            }
        }
    } catch (err) {
        console.error(`Cant get renderer:${err}`);
    }

    return null;
}

export async function getBrowserInfo() {
    const screen = pick(window.screen, ['height', 'width']);
    const devicePixelRatio = window.devicePixelRatio;
    const userAgent = window?.navigator?.userAgent;

    const meta = {
        screen,
        devicePixelRatio
    };

    const renderer = getRenderer();
    let clientHints;

    try {
        clientHints = await TsEnvironmentDetect.getBrowserClientHints();
    } catch (err) {
        console.error(`Can't get client hints: ${err}`);
    }

    const browserInfo = {
        userAgent,
        meta,
        renderer,
        ...(clientHints && {clientHints})
    };

    return browserInfo;
}
