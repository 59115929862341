export function jsonFriendlyErrorReplacer(key, value) {
    if (value instanceof Error) {
        return {
            // Explicitly pull Error's non-enumerable properties
            name: value.name,
            message: value.message,
            stack: value.stack
        };
    }

    return value;
}
