'use strict';

import {getBrowserInfo} from 'shared/browser-info.js';
import {jsonFriendlyErrorReplacer} from 'shared/error-replacer.js';
import {LOADER_STATES, LOADER_ERROR_TIMEOUT} from './loader.settings.js';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {EVENT_SOURCES} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import find from 'lodash/find';
import includes from 'lodash/includes';
import endsWith from 'lodash/endsWith';
import assign from 'lodash/assign';
// @ts-ignore
import utils from '@techsee/techsee-common/lib/utils';

window.loaderState = LOADER_STATES.RUNNING;

function _displayError(context, customError, showEmptyLoader) {
    // TODO: Better to modify the DOM using CSS, but it presented issue so we decided to go this way
    const loaderView = document.getElementById('loader-view');

    if (loaderView) {
        loaderView.innerHTML = showEmptyLoader
            ? ''
            : '<h3 style="color: white">Something went wrong.<br/>Please check your internet connection and try again</h3>';
    }

    context._errorHandler({
        location: '_displayError',
        error: customError || 'something went wrong',
        params: context.params,
        lastRedirectionUrl: context.lastRedirectionUrl
    });
}

function _getIOSVersion() {
    const ua = window.navigator.userAgent;

    if (/(iPhone|iPod|iPad)/i.test(ua)) {
        return ua
            .match(/OS [\d_]+/i)[0]
            .substr(3)
            .split('_')
            .map((n) => parseInt(n, 10));
    }

    return [0];
}

class TsLoader {
    _includeJs(filename, cb) {
        const head = document.getElementsByTagName('head')[0];

        const script = document.createElement('script');

        if (script.onload) {
            script.onload = cb;
        }

        script.src = filename;
        script.type = 'text/javascript';

        head.appendChild(script);

        if (!script.onload) {
            return cb();
        }
    }

    _redirect(redirectUrl) {
        this.lastRedirectionUrl = redirectUrl;

        window.location.replace(redirectUrl);

        if (ENV && ENV.dev) {
            window.location.reload();
        }
    }

    _handleLoadingEvents(isInRedirection) {
        this._eventLog('CLIENT_LOADING', {isAfterRedirection: isInRedirection});

        if (isInRedirection) {
            return;
        }

        this._sendReportedField('clientLoading', true);
        this._setPhoneNumber();

        getBrowserInfo().then((info) => {
            this._sendBrowserInfo(info, false, !!this.params.roomCode);

            this._eventLog('USER_AGENT', {browserInfo: info, clientVersion: CLIENT_VERSION});
        });
    }

    _handleRedirecteIos(url) {
        this._redirect(url);

        setTimeout(
            () => {
                document.getElementById('loader-container').style.zIndex = 100;
                document.getElementById('loader-view').style.display = 'none';

                const screen = document.getElementById(this.isShareApp ? 'guidance-ios' : 'continue-session-dialog');

                screen.style.display = 'initial';

                if (this.isShareApp && (this.theme === 'of' || this.theme === 'ge')) {
                    if (this.theme === 'of') {
                        document.getElementById('guidance-ios-img').style.display = 'none';
                        document.getElementById('guidance-ios-text').style.display = 'none';
                        screen.style.backgroundColor = '#D6D6D6';
                        document.getElementById('guidance-ios-text-theme').style.display = 'initial';
                    }

                    document.getElementById('guidance-ios-logo').style.display = 'none';
                    document.getElementById(`guidance-ios-logo-${this.theme}`).style.display = 'initial';
                }

                document
                    .getElementById(this.isShareApp ? 'download-app-btn' : 'continue-session-btn')
                    .addEventListener('click', () => {
                        this._eventLog('REDIRECTED_TO_STORE_BUTTON_CLICKED');
                        this._redirect(url);
                    });
            },
            this.isShareApp ? 500 : 3000
        );
    }

    _setCookie() {
        const query = Object.keys(this.params)
            .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(this.params[k])}`)
            .join('&');
        const expires = new Date(Date.now() + 1000 * 60 * 60).toGMTString(); // Set cookie expires date to one hour ahead

        document.cookie = `roomLink=https://${window.location.host}/?${query}; domain=.techsee.me; path=/; expires=${expires}`;
    }

    _isRedirectableOs(isIOS, isAndroid) {
        if (isAndroid) {
            return true;
        }

        if (!isIOS) {
            return false;
        }

        try {
            const iOSVersion = _getIOSVersion();

            // Cannot detect IOS version, assume it's new
            if (!iOSVersion || !iOSVersion[0]) {
                return true;
            }

            const iOSMajorVersion = iOSVersion[0];

            return iOSMajorVersion >= 13;
        } catch (e) {
            console.error(e);

            return false;
        }
    }

    _handleRedirection() {
        const href = `${window.location.href}`.trim().replace(/\.$/, '').trim();
        const noRedirectionUrl = `${href}&nr=1`;
        const isIOS = this._isIOS();

        this.theme = this.params.t;

        const redirectableOS = this._isRedirectableOs(isIOS, this._isAndroid());
        const shouldRedirect = redirectableOS && this.params.download === 'yes';

        if (shouldRedirect) {
            return this._getUrlToStore((redirectUrl) => {
                const newLink = encodeURIComponent(window.location.href);
                const newRedirectUrl = redirectUrl.replace('${newLink}', newLink);

                this._setCookie();

                if (redirectUrl) {
                    const key = 'mirroringStatus';
                    const value = {type: 'applicationNotInstalled'};
                    const type = 'push';

                    this._sendReportedField(key, value, type);

                    if (isIOS) {
                        this._handleRedirecteIos(redirectUrl);

                        return;
                    }

                    this._redirect(newRedirectUrl);
                }
            });
        }

        this._redirect(noRedirectionUrl);
    }

    constructor() {
        const hostname = window.location.hostname;

        this.apiURL = utils.getBackendUrl(API_URL, {hostname, ENV, AUTO_SUBDOMAIN, DEFAULT_SUBDOMAIN});
        this.statsApiUrl = STATS_API_URL ? utils.getBackendUrl(STATS_API_URL, {hostname, ENV}) : STATS_API_URL;
        this.platformType = PlatformType.mobile_web;
        this.appStoreRedirectURL = APP_STORE_REDIRECT_URL;
        this.nativeAppScheme = NATIVE_APP_SCHEME;

        this.params = {};

        // This error handler is a failsafe and will be replaced by the angular
        // service, when it runs. The reason for this is that the service will
        // always have correct data on userId/roomId, while this handler loads
        // independantly and has only whatever is passed on the url (or the
        // full url retrieved with the short one)
        window.onerror = (msg, src, line, col) => {
            this._windowErrorHandler(msg, src, line, col);
        };
    }

    init() {
        // If the useragent is google read aloud, display an error and return
        if (this._isGoogleReadAloud()) {
            return _displayError(this, 'Block google read aloud', true); // return this if the useragent is google read aloud
        }

        let href = window.location.href;

        // SMS message on some devices is not parsed correctly when a dot is the last
        // character, resulting in url that includes dot. This will remove the invalid dot
        if (endsWith(href, '.')) {
            href = href.slice(0, -1);

            this._redirect(href);
        }

        if (/unsupported$/i.test(window.location.href)) {
            window.loaderState = LOADER_STATES.FINISHED;

            return;
        }

        this._getUrlParams(href);

        if (this.params['native-redirect'] === 'yes') {
            const link = this._getCookieValue('roomLink');

            this._redirect(link ? link.replace(/^https?:\/\//i, this.nativeAppScheme) : this.nativeAppScheme);
        }

        this._getFullUrl((code) => {
            if (code !== 200 && code !== 404) {
                // Code can be 0 when the request is not sent, due to connection or abort
                if (!code) {
                    // For the unlikely case we are not aborted due to redirection, display an error after a while
                    setTimeout(() => _displayError(this), LOADER_ERROR_TIMEOUT);

                    return;
                }

                return _displayError(this);
            }

            const isInRedirection = this.params.nr;

            setTimeout(() => {
                if (window.loaderState !== LOADER_STATES.MEETING_STARTED) {
                    _displayError(this);
                }
            }, LOADER_ERROR_TIMEOUT);

            // If no-redirection flag is on, don't redirect
            if (!isInRedirection) {
                if (this._checkRedirection(href).stop) {
                    return null;
                }
            }

            if (code === 200) {
                this._handleLoadingEvents(isInRedirection);
            }

            if (window.loaderState === LOADER_STATES.REDIRECTING) {
                this._handleRedirection();
            }

            if (ENABLE_APM) {
                this._includeJs(`${ENV && ENV.production ? '/app/' : ''}js/newrelic.js`, () => {
                    // do nothing.
                });
            }

            if (window.loaderState !== LOADER_STATES.REDIRECTING) {
                const opentokVersion = this.params['opentok-version'];

                if (
                    this.params['camera-only'] !== 'yes' &&
                    this.params['media-service'] === 'OPENTOK' &&
                    opentokVersion
                ) {
                    this._includeJs(`${ENV && ENV.production ? '/app/' : ''}js/opentok.${opentokVersion}.js`, () => {
                        window.loaderState = LOADER_STATES.FINISHED;
                    });
                } else {
                    window.loaderState = LOADER_STATES.FINISHED;
                }
            }
        });
    }

    _getScriptURL() {
        // https://test.techsee.me/app/dist/loader.bundle.3196085b0683ebec51e6.js
        try {
            const script = document.currentScript || document.querySelector('script[src*="dist/loader.bundle"]');

            return script && script.src;
        } catch (err) {
            this._errorHandler({
                location: '_getScriptURL',
                error: err,
                params: this.params
            });

            console.error(`_getScriptURL error:${err}`);
        }
    }

    _avoidCircularReference(obj) {
        return function (key, value) {
            return key && typeof value === 'object' && obj === value ? 'CIRCULAR_REF' : value;
        };
    }

    _request(route, method, data, callback) {
        const req = new XMLHttpRequest();
        let url = this.apiURL + route;

        if (this.statsApiUrl && route === 'api/eventLog') {
            url = `${this.statsApiUrl}/${route}`;
        }

        if (callback) {
            req.onload = () => callback(req.response, req.status);
            req.onerror = () => {
                callback(null, req.status);
                if (route !== 'api/eventLog') {
                    this._eventLog('REQUEST_ERROR_IN_LOADER', {
                        message: `XMLHttpRequestError=${req.statusText}`,
                        fullRoute: url,
                        method: method
                    });
                }
            };
        }

        req.open(method, url);
        req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        if (data) {
            req.send(JSON.stringify(data, this._avoidCircularReference(data)));
        } else {
            req.send();
        }
    }

    _eventLog(type, meta = {}) {
        const details = {
            type: type,
            userId: 'none',
            room: this.params.room || 'none',
            sentBy: EVENT_SOURCES.client,
            meta: assign(meta, {
                side: PlatformType.mobile_web,
                clientType: this.platformType,
                fromLoader: true,
                url: window.location.href,
                userAgent: window.navigator && window.navigator.userAgent,
                scriptUrl: this._getScriptURL()
            })
        };

        this._request('api/eventLog', 'POST', details);
    }

    _sendReportedField(key, value, type) {
        if (!this.params.room) {
            return;
        }

        const eventData = {
            event: {
                key,
                value,
                type
            }
        };

        this._request(`api/rooms/${this.params.room}/setReportedField`, 'PUT', eventData);
    }

    _setPhoneNumber() {
        if (!this.params.room || !!this.params.roomCode) {
            return;
        }

        this._request(`api/rooms/${this.params.room}/setPhoneNumber`, 'PUT', {phoneNumberIndex: this.params.pni || 0});
    }

    _getUrlToStore(callback) {
        getBrowserInfo().then((info) => {
            const data = {userAgent: info.userAgent};

            if (info.clientHints) {
                data.clientHints = info.clientHints;
            }

            this._request(`api/rooms/clientRedirectedToStore/${this.params.g}`, 'POST', data, (settings) => {
                this._eventLog('REDIRECTED_TO_STORE');
                callback(settings);
            });
        });
    }

    _sendBrowserInfo(info, isNative, clientUsingFs) {
        if (!this.params.room) {
            return;
        }

        this._request(`api/rooms/deviceInfo/${this.params.room}`, 'PUT', {info, isNative, clientUsingFs});
    }

    _windowErrorHandler(msg, src, line, col) {
        this._eventLog('UNCAUGHT_EXCEPTION', {error: {msg, src, line, col, special: true}});
    }

    _errorHandler(error) {
        this._eventLog('CLIENT_LOADING_ERROR', error);
    }

    _isMeetingQuerySpecified(url) {
        const meetingQueryOptions = ['g', 't', 'room', 'roomCode', 'csi'];

        return find(meetingQueryOptions, (option) => url.indexOf(`?${option}=`) >= 0);
    }

    _isGuidActuallyAState(guid) {
        // These are the known states, the fs and end states do not qualify as guid (<4 chars), and meeting is not
        // applied as a url
        return includes(['start', 'unsupported', 'changePassword', 'magicLink', 'endNew'], guid);
    }

    _decodeUrlQuery(url) {
        if (this._isMeetingQuerySpecified(url)) {
            return url;
        }

        const guid = utils.findGuidInUrl(url);
        const theme = utils.findThemeInUrl(url);
        const phoneNumberIndex = utils.findPhoneNumberIndexInUrl(url);

        if ((!guid && !theme) || this._isGuidActuallyAState(guid)) {
            return url;
        }

        let decodedUrl = url;

        if (phoneNumberIndex) {
            decodedUrl = url.replace(`${phoneNumberIndex}/`, '/');
        }

        if (guid && theme) {
            decodedUrl = decodedUrl.replace(`/${guid}/${theme}`, `?g=${guid}&t=${theme}`);
        } else if (guid) {
            decodedUrl = decodedUrl.replace(`/${guid}`, `?g=${guid}`);
        } else {
            decodedUrl = decodedUrl.replace(`/${theme}`, `?t=${theme}`);
        }

        if (guid && phoneNumberIndex) {
            decodedUrl = `${decodedUrl}&pni=${phoneNumberIndex}`;
        }

        this._redirect(decodedUrl);

        return decodedUrl;
    }

    _getUrlParams(url) {
        try {
            if (!url) {
                return;
            }

            const decodedUrl = this._decodeUrlQuery(url);

            const query = decodedUrl.split('?')[1];
            const paramStrings = query ? query.split('&') : [];

            for (let i = 0; i < paramStrings.length; i++) {
                const [key, value] = paramStrings[i].split('=');

                this.params[key] = value;
            }
        } catch (err) {
            this._eventLog('GETURLPARAMS_ERROR', {
                url: url,
                errorMessage: err?.message
            });

            throw err;
        }
    }

    _getFullUrl(callback) {
        if (this.params && this.params.g) {
            this._request(`api/shorturl/${this.params.g}`, 'GET', null, (res, code) => {
                if (res && code === 200) {
                    return callback(this._parseFullUrlResponse(res));
                }

                this._eventLog('GETFULLURL_ERROR', {
                    prevResponseCode: code,
                    g: this.params.g,
                    res: res,
                    isRetry: !!this.params.retry
                });

                if (code >= 500 && !this.params.retry) {
                    assign(this.params, {retry: true});
                    setTimeout(() => this._getFullUrl(callback), 2000);
                } else {
                    return callback(code);
                }
            });
        } else {
            return callback(200);
        }
    }

    _parseFullUrlResponse(res) {
        // Added try-catch to handle "Unexpected token < in JSON at position 0", which we
        // could not reproduce, but needs to be handled
        try {
            const forceDisableIntent = this.params.intent === 'no';
            const parsedResponse = JSON.parse(res);

            assign(this.params, parsedResponse.params);

            if (forceDisableIntent) {
                this.params.intent = 'no';
            }

            this.clientAskedForAssistance = parsedResponse.clientAskedForAssistance;
        } catch (e) {
            this._eventLog('PARSE_FULLURL_ERROR', {
                error: JSON.stringify(e, jsonFriendlyErrorReplacer),
                g: this.params.g
            });

            return 500;
        }

        return 200;
    }

    _getCookieValue(name) {
        const val = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');

        return val ? val.pop() : '';
    }

    _checkRedirection(currentUrl) {
        if (this.params['flow-type'] === 'SELF_SERVICE' && !this.clientAskedForAssistance) {
            const appToRedirectTo = this.params['ss-app'] || utils.redirectableApplications.SELF_SERVICE_V2;
            const newUrl = utils.getApplicationRedirectionUrl(currentUrl, appToRedirectTo);
            const ott = this.params.ott ? `&ott=${this.params.ott}` : '';

            this._redirect(`${newUrl}?roomId=${this.params.room}${ott}`);

            return {stop: true};
        }

        this.isShareApp = utils.findHostingAppInUrl(currentUrl);

        // Redirect only if:
        // room/roomCode is used &&
        // ((IOS && ST || (OPENTOK != 2.13.x || TURN_SERVER)))
        if (
            (this.params.room || this.params.roomCode) &&
            ((this._isIOS() &&
                (this.isShareApp ||
                    !(
                        (this.params['media-service'] === 'OPENTOK' &&
                            includes(this.params['opentok-version'], '2.13')) ||
                        this.params['media-service'] === 'TURN_SERVER'
                    ))) ||
                (this._isAndroid() && this.isShareApp))
        ) {
            window.loaderState = LOADER_STATES.REDIRECTING;
        }

        return {};
    }

    _isIOS() {
        // see http://stackoverflow.com/questions/9038625/detect-if-device-is-ios
        return /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream;
    }

    _isAndroid() {
        // see https://davidwalsh.name/detect-android
        return window.navigator.userAgent.toLowerCase().indexOf('android') > -1;
    }

    _isGoogleReadAloud() {
        return window.navigator.userAgent.toLowerCase().includes('google-read-aloud');
    }
}

if (!window.onerror) {
    const loader = new TsLoader();

    loader.init();
}
